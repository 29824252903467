import Hero from "./Hero";

const Main = () => {
  return (
    <>
      <main>
        <Hero />
      </main>
    </>
  );
};

export default Main;
